import React from "react";

// import Layout from 'Layout'
// import Homepage from 'Homepage'
// import PreFooter from 'PreFooter'

import Header from "Header";
import SEO from 'SEO'

const IndexPageTemplate = () => {
  return (
    <>
      <SEO />
      <Header />
      <div className="c-main-homepage">
        <div className="c-main-homepage__text e-h2">New website coming soon</div>
      </div>
    </>
    // <Layout>
    //   <Homepage
    //     featuredPosts={mergeNodeFields(featuredProjects)}
    //     allPosts={mergeNodeFields(allProjects)}
    //     lazyload
    //     {...page}
    //   />
    //   <PreFooter homepage />
    // </Layout>
  );
};

export default IndexPageTemplate;
