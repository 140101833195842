import React from "react";
import { Link } from "gatsby";

import LogoSVG from "assets/img/svg/logo.svg";

const HeaderLogo = () => {
  return (
    <span className="c-header__logo c-header__logo--white">
      <Link to="/" className="u-dis-b o-fill-parent">
        <span className="u-hide-visually">Home</span>
        <LogoSVG />
      </Link>
    </span>
  );
};

export default HeaderLogo;
