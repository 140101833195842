import React from 'react'
// import { Link } from 'gatsby'
// import get from 'lodash.get'

// import Socials from './HeaderSocials'
import Logo from './HeaderLogo'

// import useSocial from 'hooks/useSocialQuery'
// import useSiteMetadata from 'hooks/useSiteMetadataQuery'

// import getNamedRouteFromChunkName from 'utils/getNamedRouteFromChunkName'

const Header = ({ context }) => {
  // const { urls } = useSocial()
  // const { siteMetadata } = useSiteMetadata()


  return (
    <header className='c-header'>
      {/* <nav className='c-header__nav'>
        <Link
          to='/about/'
          className='u-color-grey'
        >
          About
        </Link>
      </nav> */}
      <Logo />
      {/* <div className='c-header__social u-cf'>
        <Socials
          items={urls}
          siteName={siteMetadata.siteName}
        />
      </div> */}
    </header>
  )
}

export default Header
